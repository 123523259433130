<template>
    <div>
        <WaBtn />
        <MainHeader />
        <!-- Top Header -->
        <MainBanner hide-enquiry="true"/>

        <!-- Thanks you Message -->
        <div class="container px-4 py-8 mx-auto text-center">
            <h2 class="text-4xl font-semibold text-cbd-liteblue">Message Sending Successful!</h2>
            <p class="pt-4">Thank you for your kind interest in CBD Properties Sdn Bhd. We will get back to you as soon as possible.</p>

            <router-link to="/">
                <p class="block w-48 py-2 m-auto mt-4 font-semibold text-white rounded-lg bg-cbd-gray">Back To Home</p>
            </router-link>
        </div>
        <!-- End Of Thanks you Message -->

        <br><br>
        <!-- CopyRight -->
        <Copyright />
        <!-- CopyRight End -->

    </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright  from "@/components/Copyright.vue"

export default {
    components: {
    WaBtn,
    MainHeader,
    MainBanner,
    Copyright
  }
}
</script>
